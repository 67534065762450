<template>
    <div id="app">
        <!-- <layout v-if="$route.path.indexOf('/login') === -1"/> -->
        <router-view></router-view>
    </div>
</template>

<script>
import layout from './components/layout/index.vue'
export default {
    name: 'App',
    components: {
        layout
    },
    created(){
        if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))){
            this.$store.commit("change_deviceType", 'mobile')
        }else{
            this.$store.commit("change_deviceType", 'pc')
        }
    },
}
</script>

<style lang="scss" scoped>
#app{
    width: 100vw;
    height: 100vh;
    overflow: auto;
}
#app::-webkit-scrollbar {
  width: 0;
  height: 0;
}

</style>
